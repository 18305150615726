.Carousel {
  @extend %u-layout--fluid;
}

.Carousel-body {
  position: relative;
  height: 100%;
}

.slick-slider,
.slick-list,
.slick-track {
  height: 100%;
}

.Carousel {
  .slick-arrow {
    &.slick-next {
      @include cursor("/ui/img/cursor/right", 44, 20);
    }

    &.slick-prev {
      @include cursor("/ui/img/cursor/left", 44, 20);
    }
  }

  .slick-arrow {
    @include hide-text;
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;

    z-index: 2;
    // opacity: 0;
    background: transparent;
    &:focus {
      outline: none;
    }
    cursor: pointer;

    .Html--touch & {
      display: none !important;
    }
  }

  .slick-arrow.slick-next {
    right: 0;
    left: auto;
  }
  .slick-arrow.slick-prev {
  }

  .slick-slide > div{
    height:100%;
  }

  .slick-dots {
    margin: 0 auto;
    position: absolute;
    width: 20%;
    text-align: left;
    bottom: 0;
    left: 0;
    padding: 20px;
    width: 50%;
    z-index: 5;

    li {
      display: inline-block;
      cursor: pointer;
      width: 12px;
      height: 12px;
      overflow: hidden;
      border-radius: 50%;
      background: transparent;
      border: 1px solid white;
      transition: background 0.5s ease, border 0.5s ease;
      margin-right: 8px;
      position: relative;
      button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }

      &.slick-active {
        background: white;
        border: 1px solid white;
      }
      @include respond-to("mobile") {
        width: 12px;
        height: 12px;
        margin: 2px;
      }
    }
  }

  &.Carousel--hero .slick-dots {
    display: none !important;
  }
}

.Carousel-captions {
  position: absolute;
  bottom: 0%;
  left: 0;
  width: 100%;
}

.Carousel-slide {
  @extend %u-layout--fluid;
  height: 100vh;
  position: relative;
  overflow: hidden;

  pointer-events: none;

  &.slick-current {
    pointer-events: all;
  }

  & > * {
    @extend %u-layout--fluid;
  }
}

.Carousel--gallery {
  .slick-slide {
    height: 100%;
    width: 100%;

    & > * {
      height: 100%;
      width: 100%;
    }
  }
}
